<template>
    <b-card body-class="p-0" header-class="border-0">
        <template v-slot:header>
            <h3 class="mb-0">Mejora de ventas</h3>
        </template>

        <el-table class="table-responsive table-flush"
                  header-row-class-name="thead-light"
                  :data="projects.slice(0, itemLimit)">
            <el-table-column label="Sección"
                             min-width="310px"
                             prop="name"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <a href="#" class="avatar rounded-circle mr-3">
                            <b-img alt="Image placeholder" :src="row.img"></b-img>
                        </a>
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.title}}</span>
                        </b-media-body>
                    </b-media>
                </template>
            </el-table-column>
            <el-table-column label="Beneficios"
                             prop="budget"
                             min-width="140px"
                             sortable>
            </el-table-column>

            <el-table-column label="Estado"
                             min-width="170px"
                             prop="status"
                             sortable>
                <template v-slot="{row}">
                    <badge class="badge-dot mr-4" type="">
                        <i :class="`bg-${row.statusType}`"></i>
                        <span class="status">{{row.status}}</span>
                    </badge>
                </template>
            </el-table-column>
            <el-table-column label="segmentación" min-width="190px">
                <template v-slot="{row}">

                <div class="avatar-group">
                    <a v-if="row.status !== 'pendiente' && row.status !== 'demorado' && row.status !== 'completado'" href="#" class="avatar avatar-sm rounded-circle" data-toggle="tooltip"
                       data-original-title="Ryan Tompson">
                        <img alt="Image placeholder" src="img/theme/generacionSilenciosa.jpg">
                    </a>
                    <a v-if="row.status !== 'completado'" href="#" class="avatar avatar-sm rounded-circle" data-toggle="tooltip"
                       data-original-title="Romina Hadid">
                        <img alt="Image placeholder" src="img/theme/babyboomers.jpg">
                    </a>
                    <a v-if="row.status !== 'pendiente' && row.status !== 'demorado'" href="#" class="avatar avatar-sm rounded-circle" data-toggle="tooltip"
                       data-original-title="Alexander Smith">
                        <img alt="Image placeholder" src="img/theme/generacionx.jpg">
                    </a>
                    <a v-if="row.status !== 'completado' && row.status !== 'demorado'" href="#" class="avatar avatar-sm rounded-circle" data-toggle="tooltip"
                       data-original-title="Jessica Doe">
                        <img alt="Image placeholder" src="img/theme/generacionz.jpg">
                    </a>
                     <a v-if="row.status !== 'prevista' && row.status !== 'pendiente'" href="#" class="avatar avatar-sm rounded-circle" data-toggle="tooltip"
                       data-original-title="Jessica Doe">
                        <img alt="Image placeholder" src="img/theme/milenials.jpg">
                    </a>
                </div>
                </template>

            </el-table-column>

            <el-table-column label="Completado"
                             prop="completion"
                             min-width="240px"
                             sortable>
                <template v-slot="{row}">
                    <div class="d-flex align-items-center">
                        <span class="completion mr-2">{{row.completion}}%</span>
                        <div>
                            <base-progress :type="row.statusType" :value="row.completion"/>
                        </div>
                    </div>
                </template>
            </el-table-column>
         
        </el-table>
    </b-card>
</template>
<script>
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
  export default {
    name: 'light-table',
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    props: {
      itemLimit: {
        type: Number,
        default: 9
      }
    },
    data() {
      return {
        projects: [
          {
            img: 'img/theme/seccionHombre.jpg',
            title: 'Sección Hombre',
            budget: '150€',
            status: 'pendiente',
            statusType: 'warning',
            completion: 60
          },
          {
            img: 'img/theme/seccionMujer.jpg',
            title: 'Sección Mujer',
            budget: '600€',
            status: 'completado',
            statusType: 'success',
            completion: 100
          },
          {
            img: 'img/theme/seccionNino.jpg',
            title: 'Sección Niño',
            budget: '500€',
            status: 'demorado',
            statusType: 'danger',
            completion: 72
          },
          {
            img: 'img/theme/seccionZapatillas.jpg',
            title: 'Sección Zapatillas',
            budget: '90€',
            status: 'prevista',
            statusType: 'info',
            completion: 90
          },
          {
            img: 'img/theme/seccionAccesorios.jpg',
            title: 'Sección Accesorios',
            budget: '2200€',
            status: 'completado',
            statusType: 'success',
            completion: 100
          }
         
        ],
        currentPage: 1
      };
    }
  }
</script>
