<template>
  <div>
    <b-alert v-if="showError" show variant="danger" id="alertError"><a href="#" class="alert-link">{{ msgError }}</a></b-alert>
    <b-alert v-if="showOk" show variant="success" id="alertOk"><a href="#" class="alert-link">{{ msgOk }}</a></b-alert>
    <base-header class="pb-6" style="background-color:#0bdeea !important;">
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="2" />
        <b-col lg="8">
          <div class="card-wrapper">
            <card>
              <h3 slot="header" class="mb-0">Agregar ticket</h3>
              <form>
                <b-row>
                  <b-col md="12">
                    <base-input v-model="form.customer" label="Cliente" prepend-icon="fas fa-user" placeholder="Cliente"></base-input>
                  </b-col>
                  <b-col md="12">
                    <base-input label="Fecha del ticket">
                      <flat-picker slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        class="form-control datepicker"
                        v-model="form.date"
                        style="background:#fff;">
                      </flat-picker>
                    </base-input>
                  </b-col>
                  <b-col md="12">
                    <base-input label="Tipo de pago">
                      <el-select v-model="form.tpPaymentId" filterable placeholder="">
                        <el-option v-for="option in selectMetodo"
                        :key="option.label"
                        :label="option.label"
                        :value="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                  <b-col md="12">
                    <base-input label="Tipo de IVA">
                      <el-select v-model="form.tpRetentionId" filterable placeholder="">
                        <el-option v-for="option in selectIVA"
                        :key="option.label"
                        :label="option.label"
                        :value="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                  <b-col md="4">
                    <base-input v-model="concepto" label="Concepto" prepend-icon="fas fa-user" placeholder="Concepto"></base-input>
                  </b-col>
                  <b-col md="4">
                    <base-input v-model="importe" type="number" min="0" label="Importe" prepend-icon="fas fa-user" placeholder="Importe"></base-input>
                  </b-col>
                  <b-col md="4" align-self="center">
                    <base-button @click.prevent="agredaProduct" type="primary" native-type="submit">Agregar</base-button>
                  </b-col>
                  <b-col md="12">
                    <b-table v-if="items.length !== 0" :items="items" :fields="fields" striped responsive="sm">
                      <template v-slot:cell(eliminar)="row">
                        <!-- <b-button type="primary" size="sm" @click="eliminarItem(row.item)" class="mr-2 concepto2 p5x"> <img src="../../../public/borrar.png" class="mxW15x" alt=""> </b-button> -->
                        <img src="../../../public/borrar.png" class="mxW15x" alt=""  @click="items.splice(row.index, 1)">
                      </template>
                    </b-table>
                  </b-col>
                  <b-col v-if="items.length !== 0" md="4" class="mb-5 ml-2">
                    <div style="padding-left: 1rem;">Total</div>
                  </b-col>
                  <b-col v-if="items.length !== 0" md="6" class="mb-5 pl-2">
                    <div style="padding-left: 2rem;">{{ totalPrice }}</div>
                  </b-col>
                  <b-col class="form-content mb-4" md="12">
                    <div class="d-flex ml-2 align-items-center checkbox-wrapper">
                      <input v-model="factura" class="styled-checkbox checks2" id="styled-checkbox-1" type="checkbox" />
                      <label for="styled-checkbox-1">¿Quiere factura?</label>
                    </div>
                  </b-col>
                  <b-col v-if="factura" md="12">
                    <base-input v-model="form.nif" label="NIF" prepend-icon="fas fa-user" placeholder="NIF"></base-input>
                  </b-col>
                  <b-col v-if="factura" md="12">
                    <base-input v-model="form.address" label="Dirección" prepend-icon="fas fa-user" placeholder="Dirección"></base-input>
                  </b-col>
                  <b-col v-if="factura" md="12">
                    <base-input v-model="form.emailSending" label="Correo" prepend-icon="fas fa-user" placeholder="Correo"></base-input>
                  </b-col>
                  <b-col md="12">
                    <base-input v-model="form.invoiceNumber" label="Indica nº factura" prepend-icon="fas fa-user" placeholder="Nº factura"></base-input>
                  </b-col>
                  <b-col md="6">
                    <base-button @click.prevent="generarTicket" type="primary" native-type="submit">Generar</base-button>
                  </b-col>
                </b-row>
              </form>
            </card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import api from '@/api/services'
  import { mapGetters } from 'vuex'
  import { Select, Option } from 'element-ui'
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";

  export default {
    name: 'form-components',
    components: {
      flatPicker,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
        nombrePersona: '',
        metodo: '',
        fecha: null,
        factura: false,
        coste: 0,
        showError: false,
        msgError: '',
        showOk: false,
        msgOk: '',
        form: {
          customer: '',
          date: '',
          invoiceNumber: '',
          address: '',
          emailSending: null,
          nif: '',
          dotiId: '',
          tpPaymentId: '',
          tpRetentionId: '',
          ivaId: ''
        },
        fields: [
          { key: 'concepto', sortable: false },
          { key: 'importe', sortable: false },
          { key: 'eliminar', sortable: false }
        ],
        items: [],
        // selectMetodo: null,
        concepto: null,
        importe: 0,
        hour: null,
        idDoti: null,
        selectMetodo: [
          {
            label: 'Tarjeta',
            value: '1'
          },
          {
            label: 'Bizum',
            value: '2'
          },
          {
            label: 'Metálico',
            value: '3'
          },
        ],
        selectIVA: [
          {
            label: '21% Iva',
            value: '1'
          },
          {
            label: '10 % Iva',
            value: '2'
          },
          {
            label: '4 % Iva',
            value: '3'
          },
        ],
      }
    },
  
    computed: {
      ...mapGetters({
        appName: 'appName',
        authUser: 'authUser'
      }),

      totalPrice () {
        let price = 0
        this.items.forEach((item) => {
          price += parseFloat(item.importe)
        })
        return price
      }
    },
    async mounted () {
      this.fecha = new Date()
      this.form.invoiceNumber = await api.getNumeroFactura(this.authUser)
    },
    methods: {
      guardaTicket() {
        let horaReal = this.hour.split(':')
        horaReal = horaReal[0] + ':' + horaReal[1]
        const data = {
            nombre: this.nombrePersona,
            fecha: this.fecha,
            coste: this.coste,
            hora: horaReal,
            concepto: this.concepto,
            idDoti: this.idDoti,
            metodo: this.metodo
        }
        let tickets = JSON.parse(localStorage.getItem('tickets') || "[]")
        tickets.push(data)
        localStorage.setItem("tickets", JSON.stringify(tickets))
      },
      
      agredaProduct () {
        // console.log(this.importe)
        // console.log(this.concepto)
        if (this.concepto !== '' && this.importe !== 0 && this.concepto !== null) {
          this.items.push({
            concepto: this.concepto,
            importe: this.importe
          })
          this.concepto = ''
          this.importe = 0
        } else {
          this.generaError('Debes rellenar los datos de forma correcta')
        }
      },

      generaError (msg) {
        this.msgError = msg
        this.showError = true
        const ambitoThis = this
        setTimeout(() => {
          ambitoThis.showError = false
        }, "2000")
      },

      generaOk (msg) {
        this.msgOk = msg
        this.showOk = true
        const ambitoThis = this
        setTimeout(() => {
          ambitoThis.showOk = false
        }, "2000")
      },

      async generarTicket () {
        if (this.form.customer === '') {
          this.generaError('Debes rellenar el nombre del cliente')
        } else if (this.date === '') {
          this.generaError('Debes indicar una fecha correcta')
        } else if (this.tpPaymentId === '') {
          this.generaError('Debes indicar un tipo de pago')
        } else if (this.tpRetentionId === '') {
          this.generaError('Debes indicar un tipo de IVA')
        } else if (this.items.length === 0) {
          this.generaError('Debes rellenar al menos un concepto e importe')
        } else {
          this.form.conceptList = this.items
          const res = await api.setTicket(this.form, this.authUser)
          this.generaOk('Ticket agregado de forma correcta')
          // console.log(res)
          this.form = {
            customer: '',
            date: '',
            invoiceNumber: '',
            address: '',
            emailSending: null,
            nif: '',
            dotiId: '',
            tpPaymentId: '',
            tpRetentionId: '',
            ivaId: ''
          }
          this.items = []
        }
      }
    }
  }
</script>
