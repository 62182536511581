<template>
  <div>
    <b-alert v-if="showError" show variant="danger" id="alertError"><a href="#" class="alert-link">{{ msgError }}</a></b-alert>
    <b-alert v-if="showOk" show variant="success" id="alertOk"><a href="#" class="alert-link">{{ msgOk }}</a></b-alert>
    <base-header class="pb-6" style="background-color:#0bdeea !important;">
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="2" />
        <b-col lg="8">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">Agregar factura</h3>
              <!-- Card body -->
              <form>
                <!-- Input groups with icon -->
                <b-row>
                  <!-- <b-col md="12">
                    <base-input v-model="nombrePersona" label="Factura" prepend-icon="fas fa-user" placeholder="Factura"></base-input>
                  </b-col> -->
                  <b-col md="12">
                    <base-input v-model="concepto" label="Concepto" prepend-icon="fas fa-user" placeholder="Concepto"></base-input>
                  </b-col>
                  <b-col md="12">
                    <base-input v-model="emisor" label="Emisor" prepend-icon="fas fa-user" placeholder="Emisor"></base-input>
                  </b-col>
                  <b-col md="12">
                      <base-input label="Fecha del ticket">
                        <flat-picker slot-scope="{focus, blur}"
                          @on-open="focus"
                          @on-close="blur"
                          class="form-control datepicker"
                          v-model="fecha"
                          style="background:#fff;">
                        </flat-picker>
                      </base-input>
                  </b-col>
                  <b-col md="12">
                    <base-input v-model="coste" label="Importe" prepend-icon="fas fa-euro" placeholder="Coste" type="number"></base-input>
                  </b-col>
                  <b-col md="12">
                    <span>Puedes subir tu mismo el fichero o hacer la foto</span>
                  </b-col>
                  <b-col md="12">
                    <b-form-file
                      v-model="archivo"
                      @change="prueba"
                      id="inputFileToLoad"
                      :state="Boolean(file1)"
                      placeholder="Elige el fichero y arrastralo..."
                      drop-placeholder="Arrastralo aquí..."
                      style="margin-bottom: 1.5rem;"
                    ></b-form-file>
                  </b-col>
                  <b-col md="12">
                    <!-- <camara @recibo="envioDatos" /> -->
                  </b-col>
                  <b-col md="3" />
                  <b-col md="6" style="text-align:center;">
                    <base-button @click.prevent="guardaFactura" type="primary" native-type="submit">Guardar factura</base-button>
                  </b-col>
                </b-row>
              </form>
            </card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import api from '@/api/services'
  import { mapGetters } from 'vuex'
  import { Select, Option } from 'element-ui'
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  // import Camara from '../Components/Camera'

  export default {
    name: 'form-components',
    components: {
      // Camara,
      flatPicker,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
        nombrePersona: '',
        fecha: null,
        coste: 0,
        msgError: '',
        showError: false,
        showOk: false,
        msgOk: '',
        concepto: '',
        emisor: '',
        hour: null,
        file: null,
        archivo: null
      }
    },
    computed: {
      ...mapGetters({
        authUser: 'authUser'
      })
    },

    mounted () {
        this.fecha = new Date();
        navigator.permissions.query({ name: 'camera' })
        .then(permissionStatus => {
          if (permissionStatus.state === 'granted') {
            // El permiso ya fue otorgado
            startCamera();
          } else if (permissionStatus.state === 'prompt') {
            // El permiso no ha sido otorgado aún, mostrar un cuadro de diálogo para solicitarlo
            permissionStatus
              .addEventListener('change', () => {
                if (permissionStatus.state === 'granted') {
                  startCamera();
                } else {
                  // console.error('El permiso de acceso a la cámara fue denegado.');
                }
              });
          } else {
            // El permiso fue denegado
            // console.error('El permiso de acceso a la cámara fue denegado.');
          }
        });
    },
    methods: {
      async guardaFactura() {

        if (document.getElementById('photoTaken') !== null) {
          if (document.getElementById('photoTaken').style.display !== 'none')
          {
            this.file = document.getElementById('photoTaken').toDataURL()
            this.file = this.file.split(',')
            this.file = this.file[1]
          } else {
            this.file = localStorage.getItem('img')
          }
        }
        if (this.concepto === '' || this.emisor === '') {
          this.generaError('Debes rellenar los datos de forma correcta')
        } else if (this.coste === 0) {
          this.generaError('Debes introducir un importe válido')
        } else if (this.file === null && this.archivo === null) {
          this.generaError('Debes subir un archivo o realizar una fotografía')
        } else {
          this.generaOk('Ticket agregado de forma correcta')
          if (this.archivo !== null) {
            var file = this.archivo
            let reader = new FileReader()
            reader.onload = (e) => {
              const res = e.target.result
              const envio = res.split(',')
              this.guardaApi(envio[1])

            };
              reader.readAsDataURL(file)
          } else {
            this.guardaApi(this.file)
          }
        }
      },

      async guardaApi (archivo) {
        let data = {
          concept: this.concepto,
          date: this.fecha,
          total: this.coste,
          issuer: this.emisor,
          file: archivo
        }
        await api.setExpenses(this.authUser, data)
      },

      generaError (msg) {
        this.msgError = msg
        this.showError = true
        const ambitoThis = this
        setTimeout(() => {
          ambitoThis.showError = false
        }, "2000")
      },

      generaOk (msg) {
        this.msgOk = msg
        this.showOk = true
        const ambitoThis = this
        setTimeout(() => {
          ambitoThis.showOk = false
        }, "2000")
      },

      prueba () {
        var filesSelected = document.getElementById("inputFileToLoad").files;
        if (filesSelected.length > 0)
        {
          var fileToLoad = filesSelected[0];
          var fileReader = new FileReader();
          fileReader.onload = function(fileLoadedEvent) 
          {
            var imagen = fileLoadedEvent.target.result;
            
            imagen = imagen.split(',')
            localStorage.setItem("img", imagen[1])
          };
          fileReader.readAsDataURL(fileToLoad);
        }
      },

      igualoFile (imagen) {
        this.file = imagen
      }
    }
  }
</script>
