<template>
  <div>
    <b-alert v-if="showError" show variant="danger" id="alertError"><a href="#" class="alert-link">{{ msgError }}</a></b-alert>
    <b-alert v-if="showOk" show variant="success" id="alertOk"><a href="#" class="alert-link">{{ msgOk }}</a></b-alert>
    <base-header class="pb-6" style="background-color:#0bdeea !important;">
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="2" />
        <b-col lg="8">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">Scanea Bono</h3>
              <qrcode-stream @decode="onDecode" />
              <qr-code-stream @decode="onDecode" />
              <b-modal hide-footer id="modal-1" title="Bono escenado con éxito">
                <p class="my-4">El cliente compro X veces, le faltan Y para desbloquear el bono</p>
                <button @click.prevent="debloquea" class="btn primary">
                  Confirmar
                </button>
                <button @click.prevent="cancela" class="btn primary fRight">
                  Cancelar
                </button>   
              </b-modal>
            </card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { QrcodeStream  } from 'vue-qrcode-reader';


  export default {
    components: {
      QrcodeStream 
    },
    methods: {
      // handleOk () {

      // },
      onDecode(result) {
        this.$bvModal.show('modal-1')
      },

      debloquea () {

      },

      cancela () {

      }
    }
  }
</script>
<style scoped>
.fRight {
  float: right;
}
</style>
