<template>
  <b-card body-class="p-0" header-class="border-0">
    <template v-slot:header>
      <b-row align-v="center">
        <b-col>
          <div class="carddoti__header carddoti__header-kpi1">
            <h3 class="mb-0">KPI por fecha</h3>
          </div>
        </b-col>
        <b-col class="text-right">
          <!-- <base-button size="sm" type="primary">See all</base-button> -->
        </b-col>
      </b-row>
    </template>


    <el-table
      class="table-responsive table"
      :data="tableData"
      header-row-class-name="thead-light">
      <el-table-column label="Fecha" min-width="115px" prop="name">
        <template v-slot="{row}">
          <div class="font-weight-600">{{row.name}}</div>
        </template>
      </el-table-column>

      <el-table-column label="Visitantes" min-width="110px" prop="visitors">
      </el-table-column>

      <el-table-column min-width="220px" prop="progress">
        <template v-slot="{row}">
          <div class="d-flex align-items-center">
              <span v-if="row.index === '1'" class="text-success mr-2"><i class="fa fa-arrow-up"></i>5.48%</span>
              <span v-if="row.index === '2'" class="text-success mr-2"><i class="fa fa-arrow-up"></i>2.48%</span>
              <span v-if="row.index === '3'" class="text-danger mr-2"><i class="fa fa-arrow-down"></i>6.48%</span>
              <span v-if="row.index === '4'" class="text-danger mr-2"><i class="fa fa-arrow-down"></i>3.48%</span>
              <span v-if="row.index === '5'" class="text-success mr-2"><i class="fa fa-arrow-up"></i>4.48%</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </b-card>
</template>
<script>
  import { BaseProgress } from '@/components';
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
  export default {
    name: 'social-traffic-table',
    components: {
      // BaseProgress,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    data() {
      return {
        tableData: [
          {
            name: '1 Enero',
            index: '1',
            visitors: '180',
            progress: 60,
            progressType: 'gradient-danger',
          },
          {
            name: '2 Enero',
            index: '2',
            visitors: '480',
            progress: 70,
            progressType: 'gradient-success',
          },
          {
            name: '3 Enero',
            index: '3',
            visitors: '207',
            progress: 80,
            progressType: 'gradient-primary',
          },
          {
            name: '4 Enero',
            index: '4',
            visitors: '178',
            progress: 75,
            progressType: 'gradient-info',
          },
          {
            name: '5 Enero',
            index: '5',
            visitors: '245',
            progress: 30,
            progressType: 'gradient-warning',
          }
        ]
      }
    }

  }
</script>
<style>
</style>
