<template>
  <div>
    <highcharts
      :options="chartOptions"
      ref="lineCharts"
    ></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import stockInit from "highcharts/modules/stock";
// import { EventBus } from "./../event-bus.js";

stockInit(Highcharts);


export default {
  props: {
    partsdata: {
      type: Array
    }
  },

  components: {
    highcharts: Chart
  },


  data() {
    return {
      chartOptions: {
    chart: {
        type: 'pie',
        marginLeft: 150
    },
    title: {
        text: 'Ventas por trabajador'
    },
    subtitle: {
        text: 'Datos generales'
    },
    xAxis: {
        type: 'category',
        title: {
            text: null
        },
        min: 0,
        max: 4,
        scrollbar: {
            enabled: true
        },
        tickLength: 0
    },
    yAxis: {
        min: 0,
        max: 100,
        title: {
            text: 'Porcentaje',
            align: 'high'
        }
    },
    plotOptions: {
        bar: {
            dataLabels: {
                enabled: true
            }
        }
    },
    legend: {
        enabled: false
    },
    credits: {
        enabled: false
    },
    series: [{
        name: 'Porcentaje',
        data: [
            ["Pedro Perez", 74.7],
            ["Vanessa Martinez", 69.8],
            ["Laura Ramirez", 65.8],
            ["Juan Jimenez", 76.8]
        ]
    }]
      }
    };
  }
};
</script>
