<template>
  <div>
    <base-header class="pb-6" style="background-color:#0bdeea !important;">
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="1" />
        <b-col lg="10">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <h3 class="mb20x">Listado de tickets</h3>
              <b-row>
                <b-col v-if="gestoria" cols="12">
                  <base-input label="Selecciona cliente">
                    <el-select filterable multiple
                        placeholder="">
                      <el-option v-for="option in selectTienda"
                      :key="option.label"
                      :label="option.label"
                      :value="option.value">
                      </el-option>
                    </el-select>
                  </base-input>
                </b-col>
                <b-col cols="6">
                  <base-button @click.prevent="filter()" type="primary" native-type="submit" class="btnBusqueda w200X"><img src="../../../public/setting.svg" class="mxW13x" alt=""> BÚSQUEDA AVANZADA</base-button>
                </b-col>
                <b-col cols="6" class="text-right">
                    <div>
                      
                    <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none" no-caret>
                      <template #button-content>
                        <div class="W20x"><img src="../../../public/export-file.svg" class="mxW25x" alt=""></div>
                      </template>
                      <b-dropdown-item @click="exportar('PDF')">Exportar PDF</b-dropdown-item>
                      <b-dropdown-item @click="exportar('CSV')">Exportar CSV</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </b-col>
              </b-row>
              <card v-if="busqueda">
                <b-row>
                  <b-col md="12" class="mb-4">
                    <b>Búsqueda avanzada</b>
                  </b-col>
                  <b-col md="6">
                    <base-input v-model="cliente" label="Cliente" prepend-icon="fas fa-user"></base-input>
                  </b-col>
                  <b-col md="6">
                    <base-input v-model="concepto" label="Concepto" prepend-icon="fas fa-user"></base-input>
                  </b-col>
                  <b-col md="3">

                    <base-input label="Fecha mínima">
                      <flat-picker slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        class="form-control datepicker"
                        v-model="fecha"
                        @blur="filtro"
                        firstDayOfWeek="1"
                        :config="{ locale: { firstDayOfWeek: 1 } }"
                        style="background:#fff;">
                      </flat-picker>
                    </base-input>
                  </b-col>
                  <b-col md="3">
                    <base-input label="Fecha máxima">
                      <flat-picker slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        :options="{ weekdayStart: 1 }"
                        class="form-control datepicker"
                        :config="{ locale: { firstDayOfWeek: 1 } }"
                        v-model="fecha2"
                        @blur="filtro"
                        style="background:#fff;">
                      </flat-picker>
                    </base-input>
                  </b-col>
                  <b-col md="3">
                  </b-col>
                  <b-col md="3" style="text-align:center;" />
                  <b-col md="3" class="mb-3">
                    <base-button @click.prevent="bsqFilter" type="primary" native-type="submit" class="">Filtrar</base-button>
                  </b-col>
                  <b-col md="3" class="mb-3">
                    <base-button @click.prevent="resetFilters" type="primary" native-type="submit" class="bgTransparent">Limpiar</base-button>
                  </b-col>
                </b-row>
              </card>
              <b-table :items="items" :fields="fields" striped responsive="sm">
                <template #cell(nombre)="row">
                  <b-form-checkbox v-model="row.detailsShowing" @change="modificaLinea(row.toggleDetails)">
                    {{ row.item.nombre }}
                  </b-form-checkbox>
                </template>
                <template #cell(concepto)="row">
                  <div class="concepto"></div>
                  {{ row.item.concepto }}
                </template>
                <template #cell(coste)="row">
                  <b>{{ row.item.coste }}€</b>
                  <b-button type="primary" size="sm" @click="test(row.item)" class="mr-2 concepto2">
                    -
                  </b-button>
                </template>
                <template #cell(metodo)="row">
                  <div :class="row.item.metodo === 'Tarjeta' ? 'metodo' : 'metodo2'">
                    {{ row.item.metodo }}
                  </div>
                </template>
              </b-table>
            </card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
  import api from '@/api/services'
  import { Select, Option } from 'element-ui'
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import { mapGetters } from 'vuex'


  export default {
    name: 'form-components',
    components: {
      flatPicker,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
        fields: [
          { key: 'nombre', sortable: false },
          { key: 'concepto', sortable: false },
          { key: 'fecha', sortable: false },
          // { key: 'hora', sortable: false },
          { key: 'metodo', sortable: false },
          { key: 'coste', sortable: false },
        ],
        selectTienda: [
          { label: 'tienda 1', value: 'tienda 1'},
          { label: 'tienda 2', value: 'tienda 2' },
        ],
        items: [
          // {
          //   nombre: 'Juan Perez',
          //   concepto: 'Ropa',
          //   fecha: '09/08/2022',
          //   // hora: '09:30',
          //   metodo: 'Metálico',
          //   coste: '9'
          // },
          // {
          //   nombre: 'Susana Alvarez',
          //   concepto: 'Cabello',
          //   fecha: '10/08/2022',
          //   // hora: '19:00',
          //   metodo: 'Metálico',
          //   coste: '15'
          // }
        ],
        fecha: null,
        fecha2: null,
        busqueda: false,
        cliente: '',
        concepto: '',
        gestoria: false
      }
    },
    computed: {
      ...mapGetters({
        authUser: 'authUser'
      })
    },
    async mounted () {
      this.resetFilters()
      const res = await api.getTickets(this.authUser)
      this.items = []
        res.forEach((item) => {
          const fch = item.date.split('T')
          item.coste = item.total
          item.concepto = item.test
          item.fecha = fch[0]
          item.nombre = item.customer
          if (item.tpPaymentId === 1) {
            item.metodo = 'Tarjeta'
          } else if (item.tpPaymentId === 2) {
            item.metodo = 'Bizum'
          } else if (item.tpPaymentId === 3) {
            item.metodo = 'Metálico'
          }
        })
        this.items = res
      window.location.href.includes('?mod=list') ? this.gestoria = true : this.gestoria = false
    },
    methods: {
      modificaLinea (row) {
        this.items.forEach((item) => {
          // console.log(item)
        })
      },
      resetFilters() {
        this.fecha = null
        this.fecha2 = null
        this.cliente = ''
        this.concepto = ''
      },
      async bsqFilter () {
        const res = await api.getTickets(this.authUser, this.fecha, this.fecha2)
        this.items = []
        res.forEach((item) => {
          const fch = item.date.split('T')
          item.coste = item.total
          item.concepto = item.test
          item.fecha = fch[0]
          item.nombre = item.customer
          if (item.tpPaymentId === 1) {
            item.metodo = 'Tarjeta'
          } else if (item.tpPaymentId === 2) {
            item.metodo = 'Bizum'
          } else if (item.tpPaymentId === 3) {
            item.metodo = 'Metálico'
          }
        })
        let filtro = []
        res.forEach((item) => {
          let criba = false
          if (this.cliente !== '' && this.cliente !== item.nombre) {
            criba = true
          }
          if (this.concepto !== '' && this.concepto !== item.concepto) {
            criba = true
          }
          if (!criba) { filtro.push(item) }
        })
        this.items = filtro
      },
      filtro() {
        let splitDesde = null
        let splitHasta = null
        if (this.fecha !== null) { splitDesde = this.fecha.split('-') }
        if (this.fecha2 !== null) { splitHasta = this.fecha2.split('-') }
        let arrayFiltro = []
        this.items = JSON.parse(localStorage.getItem('tickets') || "[]")
        this.items.map(item => {
          let ok1 = false
          let ok2 = false
          item.correo = false
          const splitFecha = item.fecha.split('-')
          if (splitDesde !== null) {
            if (parseInt(splitFecha[0]) >= parseInt(splitDesde[0]) && parseInt(splitFecha[1]) >= parseInt(splitDesde[1]) && parseInt(splitFecha[2]) >= parseInt(splitDesde[2])) {
              ok1 = true
            }
          } else {
            ok1 = true
          } 
          if (splitHasta !== null) {
            if (parseInt(splitFecha[0]) <= parseInt(splitHasta[0]) && parseInt(splitFecha[1]) <= parseInt(splitHasta[1]) && parseInt(splitFecha[2]) <= parseInt(splitHasta[2])) {
              ok2 = true
            }
          } else {
            ok2 = true
          }
          if (ok1 == true && ok2 == true) {
            arrayFiltro.push(item)
          }
        })
        this.items = arrayFiltro
      },

      test (item) {
        // console.log('Llego??', item)
        item.correo === undefined || item.correo === false ? item.correo = true : item.correo = false
      },

      filter () {
        this.busqueda ? this.busqueda = false : this.busqueda = true
      },

      exportar (metodo) {
        if (metodo === 'CSV') {

          // Variable to store the final csv data
          var csv_data = [];

          // Get each row data
          var rows = document.getElementsByTagName('tr');
          for (var i = 0; i < rows.length; i++) {

            // Get each column data
            var cols = rows[i].querySelectorAll('td,th');

            // Stores each csv row data
            var csvrow = [];
            for (var j = 0; j < cols.length; j++) {
              // console.log(cols[j].innerHTML)
              if (j === cols.length - 1 && i !== 0) {
                const eliminaMinus = cols[j].textContent.split(' ')
                csvrow.push(eliminaMinus[0]);
              } else {
                csvrow.push(cols[j].textContent);
              }
            }

            // Combine each column value with comma
            csv_data.push(csvrow.join(";"));
          }

          // Combine each row data with new line character
          csv_data = csv_data.join('\n');

          // Call this function to download csv file
          var BOM = "\uFEFF";
          window.open('data:application/vnd.ms-excel,'+ encodeURI(BOM + csv_data));
        } else {
        var mywindow = window.open('', 'PRINT', '')
        mywindow.document.write('<table>')
        mywindow.document.write('<tr>')
        mywindow.document.write(document.getElementsByClassName('table')[0].childNodes[2].innerHTML)
        mywindow.document.write('</tr>')
        document.getElementsByClassName('table')[0].childNodes[3].childNodes.forEach((item) => {
          if (item.nodeName === 'TR') {
            mywindow.document.write('<tr>')

            let array = item.childNodes
            // console.log(array)
            for (let i = 0 ; i < array.length ; i++) {
              mywindow.document.write('<td>')
              mywindow.document.write(array[i].textContent)
              mywindow.document.write('</td>')
            }
            mywindow.document.write('</tr>')
          }
        })
        // mywindow.document.write(document.getElementsByClassName('table')[0].childNodes[3].innerHTML)
        mywindow.document.write('</table>')
        mywindow.print();
        }
      }
    }
  }
</script>
<style>
th{
  font-weight: bold !important;
  /* color:#fff !important; */
  color:#c1c1c1 !important;
  font-size:0.8rem !important;
  /* background-color:#37304a !important; */
  /* background-color:#00dfeb  !important; */
}
td {
    font-size: 1rem !important;
}

thead{
  box-shadow: 2px 0px 25px 4px rgb(105 95 151 / 6%) !important;
}

.table-striped>tbody>tr:nth-child(odd)>td, 
      .table-striped>tbody>tr:nth-child(odd)>th {
       /* background-color: #fff; */
        border:none;
      }
      .table-striped>tbody>tr:nth-child(even)>td, 
      .table-striped>tbody>tr:nth-child(even)>th {
       /* background-color: #f5f5f5; */
        border:none;
      }
      .table-striped>thead>tr>th {
        border:none;
         /* background-color: #f5f5f5; */
      }
.mb20x{
    margin-bottom:20px;
}
.mt20x{ margin-top:20px; }
.mt35x {
  margin-top:35px;
}
.btnCorreo{
  padding:10px !important;
  font-size: 12px !important;
}

.cajaCorreo{
  height: 26px;
  width: 80px;
  margin-right: 0.5rem !important;
}
/* th{
  background-color: #00dfeb !important;
} */

.metodo{
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #000;
  background-color: #ccf1ee;
  border-radius: 0.2rem;
  text-align: center;
}

.metodo2{
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #000;
  background-color: #dae6f5;
  border-radius: 0.2rem;
  text-align: center;
}

.concepto{
      width: 10px;
    height: 10px;
    background: red;
    border-radius: 50%;
    display: inline-block;
}
.concepto2{
   background:#00e0ec;
   border:0px;
   position:absolute;
   right:5%;
   border-radius: 50%;
   padding: 0px 5px;
}

.bgTransparent {
  background: transparent;
}

.btn:not(:selected):not(.selected) {
    cursor: none !important;
}

#bv-modal-example___BV_modal_header_{
  align-items: center;
  padding-bottom: 0px;
}

.btnBusqueda{
  background:#e5fcfd;
  border:none;
  color:#7096ab;
  padding: 5px;
  font-size: 12px;
  margin-bottom:20px;
}

.mxW13x{
  max-width: 13px;
}

.mxW25x{
  max-width: 25px;
}

.W20x{
  width:20px;
}

.dropdown-toggle {
  background: #e5fcfd;
  padding: 5px;
}

.w200X{
  min-width: 200px;
}
</style>