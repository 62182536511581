<template>
  <div class="dashboard-doti__main">
    <base-header class="pb-6" type="">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 d-inline-block mb-0">Detallado</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <b-breadcrumb class="breadcrumb-links">
              <b-breadcrumb-item><router-link to="/"><i class="fas fa-home"></i></router-link></b-breadcrumb-item>
              <b-breadcrumb-item><a href="#">Dashboards</a></b-breadcrumb-item>
              <b-breadcrumb-item active aria-current="page">Detallado</b-breadcrumb-item>
            </b-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
        </b-col>
      </b-row>
      <b-row aling-v="center" class="py-4">
        <b-col cols="7" lg="6" class="breadcrumb__wrapper">
          <h6 class="h2 d-inline-block mb-0">DASHBOARD</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
          </nav>
        </b-col>
        <b-col cols="12">
          <base-input label="Filtro tiendas">
            <el-select v-model="selectsTienda" filterable @change="changeFilter()">
              <el-option label="Tienda 1" value="tienda1">Tienda 1</el-option>
              <el-option label="Tienda 2" value="tienda2">Tienda 2</el-option>
              <el-option label="Tienda 3" value="tienda3">Tienda 3</el-option>
            </el-select>
          </base-input>
        </b-col>
      </b-row>
      <!-- <div class="carddoti_container">
        <div class="row mb-5">
          <div class="col-lg-3 col-md-4 col-sm-12 my-3">
            <div class="carddoti carddoti_tarjeta">
                <div class="carddoti_wrapper">
                  <div class="carddoti__header carddoti__header-card">
                    <h3>Pago con tarjeta</h3>
                  </div>
                  <div class="carddoti__footer">
                   <p class="state state_positive">2%</p>
                  </div>
                </div>
                <div class="card-doti__calc">{{ pagoTarjeta }}</div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-12 my-3">
            <div class="carddoti carddoti_efectivo">
                <div class="carddoti_wrapper">
                  <div class="carddoti__header carddoti__header-cash">
                    <h3>Pago en metálico</h3>
                  </div>
                  <div class="carddoti__footer">
                    <p class="state state_positive">3.23%</p>
                  </div>
                </div>
                <div class="card-doti__calc">{{ pagoMetalico }}</div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-12 my-3">
            <div class="carddoti carddoti_manana">
                <div class="carddoti_wrapper">
                  <div class="carddoti__header carddoti__header-morning">
                    <h3>Ventas mañana</h3>
                  </div>
                  <div class="carddoti__footer">
                    <p class="state state_negative">2%</p>
                  </div>
                </div>
                <div class="card-doti__calc">{{ ventasManana }}</div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-12 my-3">
            <div class="carddoti carddoti_tarde">
                <div class="carddoti_wrapper">
                  <div class="carddoti__header carddoti__header-evening">
                    <h3>Ventas tarde</h3>
                  </div>
                  <div class="carddoti__footer">
                    <p class="state state_positive">5%</p>
                  </div>
                </div>
                <div class="card-doti__calc">{{ ventasManana }}</div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="carddoti_container">
        <div class="row mb-5">
          <div class="col-lg-3 col-md-4 col-sm-12 my-3">
            <div class="carddoti carddoti_client">
                <div class="carddoti_wrapper">
                  <div class="carddoti__header carddoti__header-newclient">
                    <h3>Nuevos clientes</h3>
                  </div>
                  <div class="carddoti__footer">
                    <p class="state state_positive">3,49%</p>
                    <p class="datesince">Desde la semana pasada</p>
                  </div>
                </div>
                <div class="card-doti__calc">8</div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-12 my-3">
            <div class="carddoti carddoti_trafic">
                <div class="carddoti_wrapper">
                  <div class="carddoti__header carddoti__header-trafic">
                    <h3>Cliente dormido</h3>
                  </div>
                  <div class="carddoti__footer">
                    <p class="state state_positive">2,48%</p>
                    <p class="datesince">Desde la semana pasada</p>
                  </div>
                </div>
                <div class="card-doti__calc">150</div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-12 my-3">
            <div class="carddoti carddoti_ventas">
                <div class="carddoti_wrapper">
                  <div class="carddoti__header carddoti__header-ventas">
                    <h3>Ventas</h3>
                  </div>
                  <div class="carddoti__footer">
                    <p class="state state_negative">5,72%</p>
                    <p class="datesince">Desde la semana pasada</p>
                  </div>
                </div>
                <div class="card-doti__calc">386,24€</div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-12 my-3">
            <div class="carddoti carddoti_devoluciones">
                <div class="carddoti_wrapper">
                  <div class="carddoti__header carddoti__header-devoluciones">
                    <h3>Ticket medio</h3>
                  </div>
                  <div class="carddoti__footer">
                    <p class="state state_positive">3,48%</p>
                    <p class="datesince">Desde la semana pasada</p>
                  </div>
                </div>
                <div class="card-doti__calc">19,65€</div>
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card-group deck class="flex-column flex-xl-row">
        <card>
          <b-row align-v="center" slot="header">
            <b-col>
              <h6 class="text-uppercase text-muted ls-1 mb-1">Ventas</h6>
              <h5 class="h3 mb-0">Estado de ventas</h5>
            </b-col>
            <b-col md="12">
                    <base-input label="Filtro ventas">
                      <el-select v-model="selectsTipoVentas" filterable @change="changeFilterVentas()">
                        <el-option label="Meses" value="mes">Mes</el-option>
                        <el-option label="Dias" value="dia">Dia</el-option>
                      </el-select>
                    </base-input>
                  </b-col>
          </b-row>
          <div class="chart-area">
            <bar-chart
              :height="350"
              :chart-data="selectsTipoVentas !== 'mes' ? barChartStacked.chartData : barChartStacked2.chartData"
              :extra-options="selectsTipoVentas !== 'mes' ? barChartStacked.extraOptions : barChartStacked2.extraOptions"
            >
            </bar-chart>
          </div>
        </card>
      </b-card-group>
      <b-card-group deck class="flex-column flex-xl-row">
         <card>
            <grafico-lineal />
        </card>
        <card>
          <canvas id="marksChart" width="550" height="350"></canvas>
        </card>
      </b-card-group>
      <b-card-group deck class="flex-column flex-xl-row">
        <card>
          <grafico-redondo />
        </card>
      </b-card-group>
    </b-container>

  </div>
</template>
<script src="https://code.highcharts.com/highcharts.js"></script>
<script type="text/javascript" src="https://cdn.jsdelivr.net/npm/highcharts-vue@1.3.5/dist/highcharts-vue.min.js"></script>
 
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';
  import GraficoLineal from './GraficoLineal';
  import GraficoRedondo from './GraficoRedondo';

// import DoughnutChart from './doughnutChart'

  // Components
  import BaseHeader from '@/components/BaseHeader';
  import BaseProgress from '@/components/BaseProgress';
  // Lists
  import ProgressTrackList from './ProgressTrackList';
  import MembersCard from '@/views/Widgets/MembersCard';
  import TaskList from '@/views/Widgets/TaskList';

  // Tables
  import LightTable from './LightTable';
  import VectorMapCard from '@/views/Widgets/VectorMapCard'

  import {
    Select,
    Option
  } from 'element-ui'
  import { Charts } from "@/components/Charts/config";
  function randomScalingFactor() {
    return Math.floor(Math.random() * 25);
  }
  
  export default {
    components: {
      LineChart,
      BarChart,
      BaseHeader,
      BaseProgress,
      TaskList,
      VectorMapCard,
      LightTable,
      ProgressTrackList,
      MembersCard,
      GraficoLineal,
      GraficoRedondo,
      [Select.name]: Select,
      [Option.name]: Option,
      // DoughnutChart
    },
    // watch: {
    //   selectsTipoVentas () {
    //     console.log('Entroooo')
    //     if (this.selectsTipoVentas === 'mes') {
    //       console.log('Entro1')
    //       this.barChartStacked2.chartData.labels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    //     } else {
    //       console.log('Entro2')
    //       this.barChartStacked2.chartData.labels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
    //     }
    //   }
    // },
    data() {
      return {
        selectsTipoVentas: 'mes',
        selectsTienda: 'tienda1',
        pagoTarjeta: '220€',
        pagoMetalico: '140€',
        ventasManana: '200€',
        ventasTarde: '320€',
        bigLineChart: {
          chartData: {
            datasets: [
              {
                label: 'Performance',
                data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
              }
            ],
            labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          },
          extraOptions: chartConfigs.blueChartOptions,
        },
        redBarChart: {
          chartData: {
            labels: ['Generación silenciosa', 'Baby Boomers', 'Genracion X', 'Millenials', 'Generación Z'],
            datasets: [{
              label: 'Sales',
              data: [0, 50, 100, 150, 200, 250]
            }]
          }
        },

        barChartStacked2: {
          chartData: {
            labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            datasets: [{
              label: 'Generación silenciosa',
              backgroundColor: Charts.colors.theme['danger'],
              data: [
                40,
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor()
              ]
            }, {
              label: 'Baby boomers',
              backgroundColor: Charts.colors.theme['primary'],
              data: [
                30,
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor()
              ]
            }, {
              label: 'Generación X',
              backgroundColor: Charts.colors.theme['success'],
              data: [
                10,
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor()
              ]
            }, {
              label: 'Milenials',
              backgroundColor: Charts.colors.theme['info'],
              data: [
                15,
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor()
              ]
            }, {
              label: 'Generación Z',
              backgroundColor: Charts.colors.theme['default'],
              data: [
                5,
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor()
              ]
            }]

          },
          extraOptions: {
            tooltips: {
              mode: 'index',
              intersect: false
            },
            responsive: true,
            scales: {
              xAxes: [{
                stacked: true,
              }],
              yAxes: [{
                stacked: true
              }]
            }
          }
        },

        barChartStacked: {
          chartData: {
            labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            datasets: [{
              label: 'Generación silenciosa',
              backgroundColor: Charts.colors.theme['danger'],
              data: [
                40,
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor()
              ]
            }, {
              label: 'Baby boomers',
              backgroundColor: Charts.colors.theme['primary'],
              data: [
                30,
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor()
              ]
            }, {
              label: 'Generación X',
              backgroundColor: Charts.colors.theme['success'],
              data: [
                10,
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor()
              ]
            }, {
              label: 'Milenials',
              backgroundColor: Charts.colors.theme['info'],
              data: [
                15,
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor()
              ]
            }, {
              label: 'Generación Z',
              backgroundColor: Charts.colors.theme['default'],
              data: [
                5,
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor()
              ]
            }]

          },
          extraOptions: {
            tooltips: {
              mode: 'index',
              intersect: false
            },
            responsive: true,
            scales: {
              xAxes: [{
                stacked: true,
              }],
              yAxes: [{
                stacked: true
              }]
            }
          }
        }
      };
    },
    methods: {
      montarGrafico() {
          Highcharts.chart('container2', {
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
          },
          title: {
            text: 'Browser market shares in January, 2018'
          },
          tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
          },
          accessibility: {
            point: {
              valueSuffix: '%'
            }
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: false
              },
              showInLegend: true
            }
          },
          series: [{
            name: 'Brands',
            colorByPoint: true,
            data: [{
              name: 'Chrome',
              y: 61.41,
              sliced: true,
              selected: true
            }, {
              name: 'Internet Explorer',
              y: 11.84
            }, {
              name: 'Firefox',
              y: 10.85
            }, {
              name: 'Edge',
              y: 4.67
            }, {
              name: 'Safari',
              y: 4.18
            }, {
              name: 'Other',
              y: 7.05
            }]
          }]
        });
      },

      changeFilterVentas () {
        console.log('Entroooo')
        if (this.selectsTipoVentas === 'mes') {
          console.log('Entro1')
          this.barChartStacked2.chartData.labels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
        } else {
          console.log('Entro2')
          this.barChartStacked2.chartData.labels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
        }
      },
      changeFilter () {
        if (this.selectsTienda === 'tienda1') {
          this.pagoTarjeta = '200€'
          this.pagoMetalico = '300€'
          this.ventasManana = '400€'
          this.ventasTarde = '500€'
        } else if (this.selectsTienda === 'tienda2') {
          this.pagoTarjeta = '100€'
          this.pagoMetalico = '200€'
          this.ventasManana = '300€'
          this.ventasTarde = '400€'
        } else if (this.selectsTienda === 'tienda3') {
          this.pagoTarjeta = '240€'
          this.pagoMetalico = '310€'
          this.ventasManana = '300€'
          this.ventasTarde = '500€'
        }
      }
    },
    mounted() {

      var marksCanvas = document.getElementById("marksChart");

      var marksData = {
        labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        datasets: [{
          label: "2017",
          backgroundColor: "#7cb5ec",
          data: [65, 75, 70, 80, 60, 80, 65, 75, 70, 80, 60, 80]
        }, {
          label: "2018",
          backgroundColor: "#E5FCFD",
          data: [54, 65, 60, 70, 70, 75, 54, 65, 60, 70, 70, 75]
        }, {
          label: "2019",
          backgroundColor: "#11cdef",
          data: [13, 25, 30, 10, 74, 16, 23, 32, 12, 56, 23, 54]
        }, {
          label: "2020",
          backgroundColor: "#2dce89",
          data: [40, 23, 63, 10, 20, 85, 44, 25, 66, 10, 40, 25]
        }, {
          label: "2021",
          backgroundColor: "#00e0ec",
          data: [23, 63, 43, 71, 23, 51, 85, 35, 71, 30, 10, 95]
        }, {
          label: "2022",
          backgroundColor: "#f5365c",
          data: [54, 15, 30, 16, 20, 18, 34, 25, 31, 40, 20, 75]
        }]
      };
      var radarChart = new Chart(marksCanvas, {
        type: 'radar',
        data: marksData
      });
    }
  };
</script>
<style scoped>
.chartjs-render-monitor{
  height: 100% !important;
}

.f20x { font-size: 20px !important; }
</style>
