<template>
  <div>
    <base-header class="pb-6" style="background-color:#0bdeea !important;">
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="2" />
        <b-col lg="8">
          <div class="card-wrapper">
            <card>
                <h3 class="mb20x">Bonos cliente</h3>
                <b-row>
                    <b-col cols="6">
                        <span>Indique el identificador de cliente</span>
                    </b-col>
                    <b-col cols="6">
                         <base-input
                        name="Username"
                        placeholder="Id cliente"
                        rules="required">
            </base-input>
                    </b-col>
              </b-row>
              <card v-if="busqueda">
                <b-row>
                  <b-col md="4">
                    <base-input label="Desde">
                      <flat-picker slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        class="form-control datepicker"
                        v-model="fecha"
                        @blur="filtro"
                        style="background:#fff;">
                      </flat-picker>
                    </base-input>
                  </b-col>
                  <b-col md="4">
                    <base-input label="Hasta">
                      <flat-picker slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        class="form-control datepicker"
                        v-model="fecha2"
                        @blur="filtro"
                        style="background:#fff;">
                      </flat-picker>
                    </base-input>
                  </b-col>
                  <b-col md="4" class="flexCenter">
                    <el-select v-model="selectsPostal" filterable placeholder="Seleccione emisor">
                      <el-option key="Residuos Antonio" label="Residuos Antonio" value="residuosAntonio"> </el-option>
                      <el-option key="Eléctrica Paz" label="Eléctrica Paz" value="electricaPaz"> </el-option>
                    </el-select>
                  </b-col>
                  <b-col md="3" style="text-align:center;" />
                  <b-col md="3" class="mb-3">
                    <base-button @click.prevent="resetFilters" type="primary" native-type="submit" class="">Filtrar</base-button>
                  </b-col>
                  <b-col md="3" class="mb-3">
                    <base-button @click.prevent="resetFilters" type="primary" native-type="submit" class="bgTransparent">Limpiar</base-button>
                  </b-col>
                </b-row>
              </card>
              <b-table :items="items" :fields="fields" striped responsive="sm">
                <template #cell(coste)="row">
                  <b>{{ row.item.coste }}€</b>
                  <b-button type="primary" size="sm" @click="test(row.item)" class="mr-2 concepto2"> - </b-button>
                </template>
                <template #cell(emisor)="row">
                  <div v-if="row.item.metodo !== undefined" :class="row.item.metodo === 'Tarjeta' ? 'metodo' : 'metodo2'">
                    {{ row.item.metodo }}
                  </div>
                   <b-button type="primary" size="sm" @click="test(row.item)" class="mr-2 concepto2"> - </b-button>
                </template>
              </b-table>
            </card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import api from '@/api/services'
  import { Select, Option } from 'element-ui'
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'form-components',
    components: {
      flatPicker,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
         fields: [
          { key: 'Bono', sortable: false },
          { key: 'Estado', sortable: false },
          { key: 'Fecha fin de bono', sortable: false }
        ],
        // selectTienda: [
        //   { label: 'tienda 1', value: 'tienda 1' },
        //   { label: 'tienda 2',value: 'tienda 2' },
        // ],
        items: [],
        busqueda: false,
        gestoria: false
      }
    },

    computed: {
      ...mapGetters({
        appName: 'appName',
        authUser: 'authUser'
      })
    },
    async mounted () {
      // console.log(this.authUser)
      await api.getExpenses(this.authUser)

      this.resetFilters()
      window.location.href.includes('?mod=list') ? this.gestoria = true : this.gestoria = false
    },
    methods: {
      ...mapActions({
        setAppName: 'setAppName'
      }),
      async envioInfo () {
        // console.log('Llego?')
        var input = document.querySelector('input[type="file"]')
        // console.log(input)
        // console.log(input.files[0])
        // var reader = new FileReader(),
        // file = document.querySelector('input[type="file"]')
        // var data = reader.result,
        //     base64 = data.replace(/^[^,]*,/, '')

        // const obj = {
        //   hello: "world"
        // };
        // const json = JSON.stringify(obj);
        // const blob = new Blob([json], {
        //   type: 'application/json'
        // });
        // const data = new FormData();
        // data.append("document", blob);
        // axios({
        //   method: 'post',
        //   url: '/sample',
        //   data: data,
        // })
            const datos = {
                concept: 'fot',
                issuer: 'abc',
                total: 123.05,
                date: '2022-05-20'
            }
            var data = new FormData()
        var f = document.querySelector('input[type="file"]') // FileList object
          var reader = new FileReader();
          // Closure to capture the file information.
          reader.onload = (function(theFile) {
            return function(e) {
              var binaryData = e.target.result;
              //Converting Binary Data to base 64
              var base64String = window.btoa(binaryData);
              //showing file converted to base64
              // document.getElementById('base64').value = base64String;
data.append('file', base64String)
            };
          })(f);
          // Read in the image file as a data URL.
          // reader.readAsBinaryString(f);
        

        // data.append('file', JSON.stringify(input.files[0]))
        data.append('expenseRequest', JSON.stringify(datos))
        // console.log(data)
        await api.setExpenses(this.authUser, data)

      },
      resetFilters() {
        this.fecha = null
        this.fecha2 = null
        // this.items = JSON.parse(localStorage.getItem('facturas') || "[]")
      },
      filtro() {
        let splitDesde = null
        let splitHasta = null
        if (this.fecha !== null) { splitDesde = this.fecha.split('-') }
        if (this.fecha2 !== null) { splitHasta = this.fecha2.split('-') }
        let arrayFiltro = []
        this.items = JSON.parse(localStorage.getItem('facturas') || "[]")
        this.items.map(item => {
          let ok1 = false
          let ok2 = false
          if (splitDesde !== null) {
            const splitFecha = item.fecha.split('-')
            if (parseInt(splitFecha[0]) >= parseInt(splitDesde[0]) && parseInt(splitFecha[1]) >= parseInt(splitDesde[1]) && parseInt(splitFecha[2]) >= parseInt(splitDesde[2])) {
              ok1 = true
            }
          } else {
            ok1 = true
          } 
          if (splitHasta !== null) {
            if (parseInt(splitFecha[0]) <= parseInt(splitHasta[0]) && parseInt(splitFecha[1]) <= parseInt(splitHasta[1]) && parseInt(splitFecha[2]) <= parseInt(splitHasta[2])) {
              ok2 = true
            }
          } else {
            ok2 = true
          }
          if (ok1 == true && ok2 == true) { arrayFiltro.push(item) }
        })
        this.items = arrayFiltro
      },

      filter () {
        this.busqueda ? this.busqueda = false : this.busqueda = true
      }
    }
  }
</script>
<style>
th{
  font-weight: bold !important;
  color:#000 !important;
  font-size:0.8rem !important;
  font-weight: bold;
  /* background-color:#11d5e3 !important; */
}
td {
    font-size: 1rem !important;
}

thead{
  box-shadow: 2px 0px 25px 4px rgb(105 95 151 / 6%) !important;
}

.table-striped>tbody>tr:nth-child(odd)>td, 
      .table-striped>tbody>tr:nth-child(odd)>th {
       /* background-color: #f5f5f5; */
      }
      .table-striped>tbody>tr:nth-child(even)>td, 
      .table-striped>tbody>tr:nth-child(even)>th {
       /* background-color: #f5f5f5; */
      }
      .table-striped>thead>tr>th {
         /* background-color: #f6f9fc; */
      }

.mb20x{
    margin-bottom:20px;
}
.flexCenter {
  display: flex;
  align-items: center;
}

.btnBusqueda{
  background:#e5fcfd;
  border:none;
  color:#7096ab;
  padding: 5px;
  font-size: 12px;
  margin-bottom:20px;
}

.mxW13x{
  max-width: 13px;
}

.mxW25x{
  max-width: 25px;
}

.W20x{
  width:20px;
}

.dropdown-toggle {
  background: #e5fcfd;
  padding: 5px;
}

.w200X{
  min-width: 200px;
}
</style>